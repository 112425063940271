/* PRODUCT ITEM
-------------------------------------------------------------- */
.product-item {
    $b: &;
    position: relative;
    max-width: 268px;
    width: 360px;
    border: 1px solid $sherpa-blue-light;
    border-radius: 10px;
    margin: 0 auto;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    padding: 13px 19px 16px;

    @media #{$media-xs} {
        max-width: 152px;
        padding: 13px 10px 16px;
    }

    @media #{$media-to-sm} {
        padding-bottom: 0;
    }

    @media #{$media-xs} {
        margin-left: auto;
        margin-right: auto;
    }

    /* unavailable state */
    &--unavailable {
        .price-box {
            opacity: 0.65;
        }
    }

    /* product-of-the-week state */
    &--product-of-the-week {
        border: 1px solid $product-label-potw;
        border-radius: 10px;

        @media #{$media-xs} {
            border-radius: 4px;
        }

        .product-item__product-of-the-week-label {
            position: absolute;
            top: -27px;
            left: -1px;
            width: 125px;
            height: 44px;
            padding: 6px 8px;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
            text-align: center;
            border-radius: 9px;
            background-color: $product-label-potw;
            z-index: -1;
        }
    }

    /* PRODUCT IMAGE
    -------------------------------------------------------------- */
    &__img {
        position: relative;
        width: 146px;
        height: 146px;
        margin: 13px auto 20px auto;

        @media #{$media-xs} {
            width: initial;
            height: initial;
        }

        @include clearfix;

        .product-image {
            display: block;

            img {
                width: 100%;
                max-width: 190px;
                max-height: 190px;
                margin: 0 auto 5px auto;

                @media #{$media-xs} {
                    max-width: 100px;
                    max-height: 100px;
                }
            }
        }
    }

    /* PROMOTION LABEL
    -------------------------------------------------------------- */
    &__badge {
        position: absolute;
        top: 10px;
        right: -10px;
        z-index: $zindex-badge;
    }

    /* UNAVAILABLE BUTTON
    -------------------------------------------------------------- */
    &__shop--unavailable {
        justify-content: left;
    }

    /* PRODUCT ICONS
    -------------------------------------------------------------- */
    &__icons {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 5px;
        margin: 0;
        padding: 0;
    }

    .product-icons {
        &__item {
            display: inline-block;
            width: auto;
            margin: 0 4px 0 0;

            span {
                display: none;
            }
        }
    }

    /* AVAILABILITY INFO
    -------------------------------------------------------------- */
    &__unavailable {
        //@include n-center();
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 90%;
        text-align: center;
        background: rgba(255, 255, 255, 0.8);
        padding: 10px 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        a:first-of-type {
            margin-bottom: 10px;
        }

        .product-image {
            pointer-events: none;
        }

        span {
            color: $red-dark2;
            vertical-align: middle;
        }

        .icon {
            color: $red-dark2;
            font-size: 18px;

            &-wrapper {
                display: inline-block;
                margin-right: 5px;
                transform: rotate(90deg);
            }
        }

        &-link {
            color: $blue;
            cursor: pointer;

            @include n-theme() {
                color: theme(color-primary);
            }
        }
    }

    /* DESCRIPTION BOX
    -------------------------------------------------------------- */
    &__desc {
        position: relative;
        margin: 1px;
        height: 36px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
    }

    /* PRODUCT NAME
    -------------------------------------------------------------- */
    &__name {
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
    }

    &__tags {
        height: 34px;
        font-weight: 400;
        font-size: 10px;
        line-height: 17px;
        text-transform: lowercase;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__labels {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 0;

        .product-label {
            background-color: $product-label-newest;
            width: $product-label-width;
            height: $product-label-height;
            color: $product-label-text-color;
            padding: 4px 12px;
            border-radius: $product-label-border-radius;
            font-size: $product-label-font-size;
            font-weight: $font-weight-bold;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 13px 5px 5px;

            @media #{$media-to-md} {
                width: $product-label-width-mobile;
                height: $product-label-height-mobile;
                font-size: $product-label-font-size-mobile;
            }

            &--stp {
                background-color: $product-label-stp;
            }

            &--popular {
                background-color: $product-label-popular;
            }

            &--grouped {
                background-color: $product-label-grouped;
            }

            &--last_items {
                background-color: $product-label-last-items;
            }
        }
    }

    /* PRODUCT PRICE
    -------------------------------------------------------------- */
    .price-box,
    .price {
        font-size: 18px;
        font-weight: 600;
        line-height: 1;

        @media screen and (max-width: 440px) {
            font-size: 18px;
        }
    }

    .price {
        text-transform: none;
    }

    .special-price,
    .old-price {
        display: inline-block;
    }

    .old-price {
        text-decoration: line-through;

        &,
        .price {
            font-size: 16px;

            @media #{$media-md} {
                font-size: 14px;
            }
        }
    }

    /* ADD TO CART
    -------------------------------------------------------------- */
    &__shop {
        margin-top: 8px;
        width: 100%;

        @media #{$media-xs} {
            .product-item__btn {
                margin: 8px 0;
                display: flex;
                justify-content: flex-start;
                padding-left: 8px;
                height: 32px;
                align-items: center;

                .icon-cart,
                .icon-cart3,
                .icon-cart2 {
                    margin-right: 0;
                    margin-left: 6px;
                    font-size: 22px;

                    @media #{$media-xs} {
                        margin-left: 0;
                    }
                }
            }

            .icon-search {
                display: none;
            }
        }

        .price {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;

            @media #{$media-xs} {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
            }
        }

        .price-label {
            margin-bottom: 8px;
        }
    }

    &__btn {
        width: 100%;
        position: relative;
        display: block;
        min-width: 0;
        min-height: 0;
        padding: 8px 0;
        margin-top: 8px;
        font-size: 16px;
        font-weight: $font-weight-medium;
        border-radius: 32px;

        @include n-hover(blue, border-color);

        @include n-theme() {
            border-color: theme(color-primary);
        }

        .add-to-cart {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;

            @media #{$media-xs} {
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
            }
        }

        &:hover,
        &:active,
        &:focus {
            &,
            .price {
                color: white !important;
            }

            .icon {
                fill: white;

                svg {
                    fill: white;
                }
            }
        }

        .input-group {
            display: contents;
        }

        .icon {
            font-size: 30px;
            margin-right: 10px;
            fill: $sherpa-blue;
        }

        .label {
            display: inline-block;
            vertical-align: middle;
        }

        .input-group-btn {
            display: inline-block;
        }

        &--unavailable {
            border-color: $gray-darker4;
            pointer-events: none;
            cursor: default;

            .price {
                color: $gray-darker4;
            }

            .input-group {
                display: inline-block;
            }
        }
    }

    /* RX PRODUCTS
    -------------------------------------------------------------- */
    &__rx {
        position: absolute;
        background: #fff;
        right: 0;
        left: 0;
        overflow: hidden;

        p {
            @include n-center(absolute);
            left: 35px;
            right: 35px;
            margin: 0 auto;

            @media #{$media-md} {
                left: 25px;
                right: 25px;
            }

            .product-item--unavailable & {
                top: 45%;
            }
        }

        strong {
            display: block;
            font-size: 15px;
            line-height: 1.2;
            color: palette(green);
            position: absolute;
            left: 50%;
            bottom: 12%;
            transform: translate(-50%, -50%);

            @include n-theme() {
                color: theme(color-primary);
            }

            @media #{$media-xs} {
                font-size: 12px;
            }
        }

        .badge-icon {
            display: inline-block;
            margin-bottom: 15px;

            @media #{$media-md} {
                margin-bottom: 7px;
            }

            .product-item--unavailable & {
                margin-bottom: 5px;
            }
        }
    }

    .rx-wrapper {
        height: 0;
        padding-bottom: 100%;
        width: 100%;
        position: relative;
    }

    .rx-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* PRODUCT ICONS GLOBAL STYLES
-------------------------------------------------------------- */
.product-icons {
    display: table;

    .badge-icon {
        vertical-align: middle;
        width: 35px;
        height: 35px;

        img {
            height: 22px;
        }
    }
}

/* Special styles for product item
-------------------------------------------------------------- */

.homepage-products,
.navigation-recently,
.product-carousel,
.sales-page-products__slider {
    .product-item__img {
        img {
            margin-top: 37px;
            margin-bottom: 0;
        }
    }
}

.catalog {
    .product-item {
        display: block;
        max-width: 270px;
        height: 360px;
        padding: 6px 20px;
        border-radius: 10px;
        border: 1px solid #cee7d8;
        &.-list {
            height: 152px;
            display: flex;
            justify-content: center;
            align-items: center;

            .product-item__labels {
                flex-direction: row;
                .product-label {
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
            .mobile-column-for-row-view {
                align-items: center;
                margin-right: 45px;
                gap: 15px;
            }
            @media #{$media-xs} {
                height: 228px;
                align-items: flex-start;
                padding-top: 15px;

                .mobile-column-for-row-view {
                    margin-top: 35px;
                    margin-right: 0;
                    gap: 0;
                    align-items: flex-start;
                }
            }
        }

        &--product-of-the-week {
            border: 1px solid #e83968;
            border-radius: 10px;
        }

        @media #{$media-xs} {
            border-radius: 4px;
            padding: 7px 12px;
            max-width: 152px;
            height: 320px !important;
        }

        &__img {
            display: block;
            min-height: 0;
            height: auto;
            width: auto;
            margin: 10px auto;
            border-bottom: none;
            padding-bottom: 0;
            @media #{$media-xs} {
                margin: 14px 13px;
            }

            .product-image {
                width: 146px;
                height: 146px;
                margin-bottom: 0;

                img {
                    width: 146px;
                    height: 146px;
                    max-width: 146px;
                    max-height: 146px;
                    margin-bottom: 0;
                }

                @media #{$media-xs} {
                    width: 100px;
                    height: 100px;
                    max-width: 100px;
                    max-height: 100px;
                    margin-bottom: 0;
                    img {
                        width: 100px;
                        height: 100px;
                        max-width: 100px;
                        max-height: 100px;
                        margin-bottom: 0;
                    }
                }
            }
            &.-list {
                .product-image {
                    margin-top: 10px;
                    width: 100px;
                    height: 100px;
                    img {
                        width: 100px;
                        height: 100px;
                        max-width: 100px;
                        max-height: 100px;
                        margin-bottom: 0;
                    }
                }
                @media #{$media-xs} {
                    margin-top: 10px;
                    .product-image {
                        margin-top: 0;
                        width: 90px;
                        height: 90px;
                        max-width: 90px;
                        max-height: 90px;
                        margin-bottom: 0;
                        img {
                            width: 90px;
                            height: 90px;
                            max-width: 90px;
                            max-height: 90px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &__desc {
            height: auto;
            padding: 0;
            margin: 0;
            @media #{$media-xs} {
                padding: 0;
                margin-bottom: 8px;
            }
            &.-list {
                .product-item__name {
                    height: auto;
                    max-height: 40px;
                    margin-bottom: 8px;
                    @media #{$media-xs} {
                        height: 32px;
                        padding-bottom: 0;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
                .product-item__tags {
                    height: auto;
                    max-height: 34px;
                    @media #{$media-xs} {
                        font-size: 10px;
                        line-height: 17px;
                    }
                }
            }
        }
        &__name {
            height: 44px;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media #{$media-xs} {
                height: 32px;
                padding-bottom: 0;
                font-size: 12px;
                line-height: 16px;
            }
        }
        &__shop {
            margin-top: 10px;
            &.-list {
                align-items: center;
                form {
                    align-items: center;
                }
                .price-label {
                    margin-bottom: 0;
                }

                .tooltip-max-price-info {
                    top: auto;
                    right: 24px;
                    bottom: -26px;
                }
                @media #{$media-xs} {
                    margin-top: 0;
                    form {
                        align-items: flex-start;
                    }
                    .price-label {
                        margin-bottom: 8px;
                    }
                    .price {
                        font-size: 12px;
                        line-height: 16px;
                    }
                    .tooltip-max-price-info {
                        bottom: -40px;
                    }
                    .input-group-btn {
                        scale: 1.2;
                    }
                }
            }
            .price {
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
            }
            .text {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
            }

            .input-group-btn {
                width: 20px;
            }
            .tooltip-max-price-info {
                padding: 0;
                margin: 0;
                p,
                span {
                    white-space: nowrap;
                    font-size: 8px;
                    line-height: 10px;
                }
                br {
                    display: none;
                }
            }

            @media #{$media-xs} {
                .keywords {
                    margin-bottom: 4px;
                }
                .price {
                    font-size: 12px;
                    line-height: 16px;
                }
                .text {
                    font-size: 10px;
                    line-height: 12px;
                }
                .input-group {
                    display: block;
                    line-height: 10px;
                    width: 18px;
                    height: 16px;
                }
                .input-group-btn {
                    svg {
                        margin-left: -5px;
                        margin-top: 0.5px;
                        scale: 0.9;
                    }
                }
                .tooltip-max-price-info {
                    padding: 0;
                    margin: 0;
                    p,
                    span {
                        white-space: normal;
                        font-size: 7px;
                        line-height: 12px;
                    }
                    br {
                        display: block;
                    }
                }
            }
        }
        &__btn {
            height: 32px;
            min-height: 32px;
            margin: 10px 0;
            padding: 0;
            .label .add-to-cart,
            .label {
                line-height: 16px;
                font-size: 13px;
                vertical-align: unset;
                @media #{$media-xs} {
                    line-height: 12px;
                    font-size: 10px;
                }
            }

            @media #{$media-xs} {
                margin: 8px 0;
                padding: 10px;
            }
        }
    }

    .product-item__labels {
        .product-label {
            margin-left: 16px;
        }
        z-index: 5;
    }
    .wishlist-container {
        position: absolute;
        z-index: 4;
        top: 15px;
        right: 15px;
        @media #{$media-xs} {
            top: 6px;
            right: 6px;
        }
        &.-list {
            top: auto;
            height: 40px;
            right: 30px;
            @media #{$media-xs} {
                top: 10px;
                right: 10px;
            }
        }
    }
    .product-shop__wishlist {
        &.as-placeholder {
            display: block;
        }

        button {
            min-height: 22px;

            &[disabled],
            &[disabled]:hover {
                background: transparent !important;
                border-color: transparent !important;
                border: 0 !important;
            }
        }

        .link-wishlist.wishlist-added {
            .product-options__icon {
                background: $dark-green;

                path {
                    stroke: $white;
                }
            }

            &:hover {
                .product-options__icon {
                    background: $white;

                    path {
                        stroke: none;
                        fill: $gray-dark;
                    }

                    @media (max-width: 480px) {
                        background: $dark-green;
                        path {
                            stroke: $white;
                        }
                    }
                }
            }
        }

        .product-options {
            &__icon {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #fff;
                border-radius: 50%;
                box-shadow: 0px 2px 15px rgba(183, 189, 196, 0.570852);
                margin-bottom: 7px;
            }
        }
    }
    .product-item--unavailable,
    .product-item--refunded {
        .product-item__shop {
            &.-list {
                .product-item__btn {
                    margin-top: 0;
                }
            }
        }

        .product-item__btn {
            margin-top: 43px;
            padding: 0;

            .input-group {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                min-height: 30px;
                gap: 5px;
            }
            .label {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
            }
            .input-group-btn {
                width: 20px;
                svg {
                    scale: 0.7;
                }
            }

            @media #{$media-xs} {
                margin: 8px 0;
                margin-top: 28px;
                padding: 10px;
                align-items: center;
                justify-content: center;

                .label {
                    font-size: 10px;
                    line-height: 12px;
                }
                .input-group {
                    gap: 10px;
                    width: 100%;
                }
                .input-group-btn {
                    margin-top: -7px;
                    width: 20px;
                    height: 20px;
                    svg {
                        scale: 0.7;
                    }
                }
            }
        }
    }
    .product-item--unavailable {
        padding: 6px 20px;

        &.-list {
            .mobile-column {
                width: 55%;
            }
            .product-item__unavailable {
                bottom: 10px;
            }
        }

        .product-item__btn {
            height: 40px;
            min-height: 32px;
            line-height: 12px;
            font-size: 14px;
            font-weight: 600;

            @media #{$media-xs} {
                text-wrap: wrap;
                line-height: 12px;
                font-size: 10px;
            }
        }
    }
}
