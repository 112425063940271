.page-links {
    $b: &;
    width: 100%;
    background-color: $body-bg;
    height: 64px;
    overflow: scroll;
    scrollbar-width: none;


    @media #{$media-xs} {
        .page-links {
            &__wrapper {
                &.container {
                    justify-content: initial;
                }
            }
        }
    }


    @media #{$media-xl} {
        .container {
            width: 1622px !important;
        }
    }

    @media #{$media-to-sm} {
        .container {
            flex-wrap: nowrap;
        }
    }

    @media #{$media-xs} {
        height: 50px;
    }
    #{$b}__wrapper.container {
        justify-content: center;
        gap: 0;
        @media #{$media-xs} {
            justify-content: initial;
        }
    }

    #{$b}__wrapper {
        display: flex;
        height: 100%;

        @media #{$media-xs} {
           padding-left: 0;
           padding-right: 0;
        }

        @media #{$media-to-sm} {
            &.container {
                flex-wrap: nowrap;

                .page-links__item span,
                .page-links__item--swiat-zdrowia span,
                .page-links__item--blog span {

                    font-size: 10px;
                    line-height: 15px;
                }

                .page-links__item {
                    &:before {
                        height: 34px;
                        padding-top: 0;
                        width: 90%
                    }

                    @media #{$media-xs} {
                        min-width: 148px;
                    }
                }
            }
        }
    }

    &__item {
        font-size: 16px;
        font-weight: $font-weight-bold;
        padding-bottom: 6px;
        position: relative;
        width: 400px;
        height: 100%;
        color: $sherpa-blue;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        z-index: 1;
        overflow-y: hidden;

        &:hover {
            color: $btn-default-hover;
        }

        @media #{$media-xs} {
            font-size: 10px;
            font-weight: $font-weight-light;
            min-width: 45%;
        }

        &:before {
            content: '';
            background: $body-bg;
            width: 90%;
            height: 47px;
            position: absolute;
            display: block;
            z-index: -1;
            bottom: 2px;
            border: 1px solid #6D7280;
            border-bottom: none;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            @media #{$media-xs} {
                width: 100%;
                height: 36px;
            }
        }

        &--blog,
        &--swiat-zdrowia {
            bottom: 2px;
            @media #{$media-xs} {
                bottom: initial;
            }

        }

        &--products,
        &--blog,
        &--swiat-zdrowia {
            @media #{$media-xs} {
                font-weight: $font-weight-xbold;
            }

            &:hover {
                span {
                    color: $btn-default-hover;
                }

                &:before {
                    background: $white;
                }
            }

            &:before {
                background: $white;
            }
        }
    }

    &__item--swiat-zdrowia,
    &__item--blog {
        span {
            font-size: 16px;
            line-height: 28px;
            font-weight: 400;
            color: #6D7280;
        }

        &:before {
            border-top: 1px solid #CDD3D3;
            border-left: 1px solid #CDD3D3;
            border-right: 1px solid #CDD3D3;
            border-bottom: none;
            bottom: 2px;
        }
    }

    .page-links__item--products {
        &:before {
            color: $dark-green;
            bottom: -1px;
            z-index: 99;
            background: #fff;
            padding-top: 48px
        }
        span{
            z-index: 100;
        }
        z-index: 1020;
    }
}

.page-links.typo-header {
    .page-links__item--products {
        &:before {
            color: #36363f;
            bottom: 2px;
            z-index: 1;
            padding-top: initial;
            background: $white;
            border-top: 1px solid #CDD3D3;
            border-left: 1px solid #CDD3D3;
            border-right: 1px solid #CDD3D3;
            border-bottom: 1px solid  #6D7280;
            border-bottom: none;
        }

        &:hover {
            &:before {
                background: #fff;
            }
        }

        span {
            font-weight: 400;
            z-index: 100;
        }

        z-index: 100;
    }
    .page-links__item--blog {
        &:before {
            color: $dark-green;
            bottom: 0px;
            z-index: 99;
            background: #fff;
            padding-top: 48px;
            border: 1px solid #6D7280;
            border-bottom: none;
        }

        span {
            color: $sherpa-blue;
            z-index: 100;
            font-weight: 600;
        }

        z-index: 1020;
    }

    .page-links__item--swiat-zdrowia {
        &:before {
            bottom: 2px;
        }
    }
}

.cms-content {
    .page-links {
        &__item {
            &--products {
                color: $blue-dianne;

                &:before {
                    background:  $body-bg;
                }
            }

            &--blog {
                color: $sherpa-blue;

                @media #{$media-xs} {
                    font-weight: $font-weight-xbold;
                }

                &:hover {
                    color: $btn-default-hover;
                }

                &:before {
                    background:  $white;
                }
            }
        }
    }
}
