.header-main,
.header-sticky {

     /* #USP BAR
    -------------------------------------------------------------- */
    $usp-top-height: 64px;

    &__usp {
        @media #{$media-xl} {
            .container {
                width: 1622px !important;
            }
        }

        position: relative;
        height: $usp-top-height;
        overflow: hidden;
        background: $gray-light;
        font-size: 14px;
        font-weight: $font-weight-light;
        line-height: 18px;
        color: $blue-dianne;

        &-content {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            height: 100%;
        }

        ul {
            display: inline-flex;
            flex-wrap: nowrap;
            width: 100%;
            justify-content: center;

            @media #{$media-xs} {
                justify-content: flex-start;
                overflow-x: scroll;
            }

            .icon {
                width: 24px;
                height: 24px;
                margin: 0 10px;

                &.icon-free-delivery-usp {
                    width: 35px;
                }
            }
        }

        li {
            white-space: nowrap;
            display: flex;
            align-items: center;

            @media #{$media-xs} {
                font-size: 10px;
            }
        }

        a {
            color: inherit;
            display: flex;
            align-items: center;
        }

        .container {
            height: 100%;

            @media #{$media-xs} {
                padding: 0;
            }
        }
    }
}
