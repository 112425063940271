@import 'header/page-links';
@import 'header/usp';
@import 'header/header-overlay';
@import 'header/header-options';


/* HEADER MAIN
-------------------------------------------------------------- */

#header-main {
    position: relative;
}

.header-main,
.header-sticky {
    .customer-account-login &__wrapper,
    .checkout-onepage-login &__wrapper {
        display: none;
    }

    .customer-account-login &__wrapper,
    .checkout-onepage-login &__wrapper {
        display: none;
    }

    @media #{$media-to-sm} {
        margin-bottom: 0;
    }

    .cms-home & {
        @media #{$media-xs} {
            margin-bottom: 0;
        }
    }

    .input-text {
        &::placeholder {
            font-style: normal;
            @include n-theme() {
                color: theme(color-primary);
            }
        }
    }

    a {
        text-decoration: none;
    }

    .btn-nav {
        display: inline-block;
    }

    .btn--clear {
        text-transform: none;
        color: inherit;

        i + span {
            margin: 0;
        }
    }

    /* Logo
    -------------------------------------------------------------- */
    .logo {
        font-size: 32px;
        line-height: 1;
        position: relative;
        margin: 0 0 10.5px 0;

        @media #{$media-sm} {
            bottom: 0;
        }

        &__img {
            @media #{$media-sm} {
                width: 160px;
            }

            @media #{$media-xs} {
                width: 120px;
            }

            @media screen and (max-width: 350px) {
                width: 80px;
            }
        }

        &__name {
            display: none;
        }
    }

    /* #TOP BAR
    -------------------------------------------------------------- */
    &__top {
        min-height: 44px;
        background: palette(gray, light-hover);
        padding-top: 4px;
        border-top: 1px solid palette(gray, lighter3);
        border-bottom: 1px solid palette(gray, lighter4);
        color: #7b7b7b;

        @media #{$media-xs} {
            border-bottom: none;
        }

        > .container {
            position: relative;
            display: table;
            z-index: $zindex-suggester + 1;

            .suggester-is-focus &,
            .dropdown-is-open & {
                @media #{$media-sm-up} {
                    z-index: 0;
                }
            }

            @media #{$media-xs} {
                width: 100%;
            }
        }

        .tab-quick {
            height: 38px;
        }
    }

    /* NAV WRAPPER
    -------------------------------------------------------------- */
    &__wrapper {
        .header-is-sticky & {
            @media #{$media-md-up} {
                min-height: 52px;
            }
        }
    }


    /* SWITCHER
    -------------------------------------------------------------- */
    &__switcher {
        display: table-cell;
        z-index: $zindex-suggester + 1;

        .tab-quick {
            float: left;
        }
    }

    &__quick {
        display: table-cell;

        @media #{$media-xs} {
            position: relative;
        }

        .tab-quick {
            float: right;
        }

        .icon {
            font-size: 18px;
        }

        .dropdown-menu {
            left: auto;
            right: 0;
            z-index: $zindex-suggester + 1;

            @media #{$media-sm-up} {
                margin-top: 8px;
            }
        }

        .badge-icon {
            @include n-badge-size(16px);
            @include n-badge-color(#737373);
            margin-right: 7px;
            line-height: 10px;

            i {
                font-size: 9px;
                line-height: 1;
                margin: 0;
            }

            & + span {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    /* #MIDDLE PART
    -------------------------------------------------------------- */
    &__middle {
        padding: 48px 0 16px;
        border-top: 1px solid #6D7280;
        margin-top: -1px;
        background: #fff;
        height: 124px;

        @media #{$media-to-sm} {
            position: relative;
            background: #fff;
            padding: 10px 0;
            height: initial;
        }

        @media #{$media-xs} {
            padding: 12px 0 36px;
        }

        .logo {
            @media #{$media-xs} {
                padding-left: 15px;
                bottom: 0;
            }

            &__img {
                @media #{$media-md-up} {
                    margin-bottom: -18px;
                    width: 190px;
                }

                @media #{$media-md} {
                   width: 160px;
                }
            }
        }

        .suggester {
            width: auto;

            & > .input-group-btn {
                @media #{$media-md-up} {
                    position: absolute;
                    bottom: 21px;
                    left: 46px;
                }
            }
        }
    }

    .header-main__middle {
        background: $white;

        @media #{$media-xl} {
            .container {
                width: 1622px !important;
            }
        }

        .container {
            align-items: center;
            max-width: 1170px;

            @media #{$media-xs} {
               padding: 0;
            }
        }
    }

    /* #SEARCH
    -------------------------------------------------------------- */
    &__search {
        display: block;

        button {
            min-width: auto;
            min-width: initial;

            @media #{$media-lg} {
                padding: 0 20px;
            }

            @media #{$media-sm-to-md} {
                width: 60px;
            }
        }
    }

    /*  SUGESSTER
    -------------------------------------------------------------- */
    .suggester {
        @media #{$media-to-sm} {
            height: 60px;
            width: 100%;
            order: 1;
            margin-top: 5px;
        }

        @media #{$media-xs} {
            margin-top: 8px;
        }

        @media #{$media-lg-up} {
            margin-left: 50px;
        }

        &__form {
            position: absolute;
            width: 504px;
            height: 60px;
            left: 20px;
            top: -7px;

            @media #{$media-to-xl} {
                left: 40px;
            }

            @media #{$media-to-md} {
                width: 343px;
                left: 92px;
            }

            @media #{$media-to-sm} {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }


    /* #NAVIGATION
    -------------------------------------------------------------- */
    &__nav {
        position: relative;
        background-color: $sherpa-blue;

        .dropdown-is-open &,
        .header-is-sticky & {
            z-index: $zindex-navbar-fixed - 2;
        }

        &:after {
            content: '';
            width: 100%;
            background-color: #000;
            position: absolute;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            top: 62px;
            @include n-transition(opacity, 0.3s);
            backface-visibility: hidden;
            transform: translateZ(0) scale(1, 1);
        }

        .dropdown-is-open & {

            @media #{$media-md-up} {
                &:after {
                    opacity: 0.37;
                    visibility: visible;
                    height: 100vh;
                }
            }
        }

        .dropdown-is-open.menu-is-open & {
            &:after {
                top: 0;
                z-index: -1;
            }
        }

        .header-is-sticky & {
            position: fixed;
            width: 100%;
            opacity: 0;

            .container {
                width: 100%;
                padding: 0;
            }

            .nav-main {
                width: 100%;

                &__primary {
                    @media #{$media-lg} {
                        width: $container-large-desktop - $grid-gutter-width;
                        margin: 0 auto;
                    }

                    > li:first-of-type {
                        &:hover {
                            > a {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .tab-nav__item {
                border-radius: 0;
            }
        }

        .menu-is-open & {
            @media #{$media-xs} {
                bottom: 0;
                overflow: auto;
            }
        }

        &.mobile-menu-open {
            opacity: 1 !important;
            z-index: 30000000;
        }
    }

    /* BUTTONS
  -------------------------------------------------------------- */
    &__button {
        min-width: unset;
        min-height: unset;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
            margin-right: 10px;

            @media #{$media-xs} {
                margin-right: 0;
            }
        }

        & > span {
            @include n-theme() {
                color: theme(color-primary);
            }

            &.label {
                overflow: hidden;
                margin-left: 10px;
            }

            &.overflow {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 130px;
            }
        }

        strong {
            white-space: nowrap;
        }

        @media #{$media-xs} {
            flex: initial;
        }
    }

    .account-dropdown {
        right: 0;
        left: auto;
        left: initial;
        z-index: 4004;
        top: 67px;

        .cms-content & {
            top: 62px;
        }

        @media #{$media-xs} {
            left: -200px;
        }

        @media #{$media-xs-l} {
            left: -145px;
        }

        &::before {
            @media #{$media-sm} {
                right: 51px;
            }

            @media #{$media-xs} {
                right: 69px;
            }

            @media #{$media-xs-l} {
                right: 118px;
            }
        }
    }

    .account-dropdown-button {
        position: relative;
    }

    .container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        @media #{$media-to-sm} {
            flex-wrap: wrap;
        }

        @media #{$media-xs} {
            justify-content: center;
        }

        &__fill {
            flex: 1;
        }
    }

    .header-options {
        &__map {
            .icon {
                @media #{$media-xs} {
                    max-width: 24px;
                }
            }
        }

        &__account {
            .icon {
                @media #{$media-xs} {
                    max-width: 24px;
                }
            }
        }

        &__minicart {
            .icon {
                @media #{$media-xs} {
                    max-width: 24px;
                }
            }

        }
    }

    &__usp {
        ul {
            padding: 0;

            li {
                cursor: default;
            }
        }
    }
}

.header-sticky {
    .logo {
        &__img {
            width: 150px;

            @media #{$media-md} {
               width: 120px;
            }

            @media #{$media-xs} {
                width: 120px;
            }

            @media screen and (max-width: 350px) {
                width: 80px;
            }
        }
    }
}

body.is-mobile-menu-open {
    overflow: hidden;

    #header-overlay-backdrop {
        @media #{$media-sm} {
            display: block;
            -webkit-animation: backdropShow 0.33s ease;
            -o-animation: backdropShow 0.33s ease;
            animation: backdropShow 0.33s ease;
        }
    }
}

body.is-mobile-menu-closing {
    #header-overlay-backdrop {
        @media #{$media-sm} {
            -webkit-animation: backdropHide 0.33s ease;
            -o-animation: backdropHide 0.33s ease;
            animation: backdropHide 0.33s ease;
        }
    }
}

.header-main__top {
    display: none;
}

/* hide usp bar on o-nas page */
body.cms-o-nas {
    .header-main__usp {
        display: none;
    }
}

.select-pharmacy-pin {
    & > span {
        // initialize it with script to avoid glitch effect
        opacity: 0;
    }
}

@keyframes backdropShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes backdropHide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

// SD-fix, refactor for new header
.header-main {
    .login-cookie {
        .label {
            margin-left: 10px;
            overflow: hidden;
            color: $sherpa-blue;
        }
    }
}

.pharmacyBase {
    .pharmacy-top {
        &.row {
            margin-bottom: 20px;
            padding: 0 15px 0 15px;
        }

        .pharmacy-description {
            font-size: 16px;
            color: $blue-dianne;
            margin-top: 30px;
        }
    }
    .title-underline {
        position: relative;
        padding-bottom: 10px;
    }
    h1 {
        font-size: 29px;
        @media #{$media-to-sm} {
            font-size: 24px;
        }
    }
    h2 {
        font-size: 24px;
        @media #{$media-to-sm} {
            font-size: 20px;
        }
    }
    h3 {
        font-size: 17px;
    }
}
.breadcrumbs {
    padding-left:0;
}
@media only screen and (max-width: 1199px) {
    padding-left:0;
}
